<template>
    <div v-if="error" class="text-danger">
        {{ error }}
    </div>
    <v-progress-circular left indeterminate size="25" v-else-if="isLoading" />
    <div class="text-left" v-else>
        <div>
            <v-btn
                v-for="link in links"
                :key="link.title + link.url"
                color="primary"
                target="_blank"
                :href="link.url"
                :loading="isLoading"
                :disabled="link === null"
                text
                @click="updateSME(link)"
            >

                <v-icon lef>icon-events</v-icon>
                Open {{ link.title }}'s Calendar
            </v-btn>
        </div>

        <v-btn color="success" :disabled="!selectedSME" @click="confirmSME">Confirm that you have an appointment with {{selectedAgent}}</v-btn>
        <p class="my-1"><v-btn color="primary--text" :disabled="!selectedSME">Set an appointment with a different agent</v-btn></p>

    </div>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    data() {
        return {
            links: [],
            isLoading: false,
            error: '',
            selectedSME: null,
        }
    },

    mounted() {
        this.loadDFLCalendarUrl()
    },

    methods: {
        loadDFLCalendarUrl() {
            this.isLoading = true
            this.error = ''
            QuilityAPI
                .getAgentConfigsBaseshopAppointmentCalendar(this.user.AgentCode)
                .then((response) => {
                    this.links = response.data.map((d) => ({ title: d.Agent.AgentName, url: d.CalendarURL, agentcode: d.Agent.AgentCode }))

                    if (this.links.length === 0) {
                        this.error = 'Agents do not have calendar URLs set'
                    }
                    this.isLoading = false
                })
                .catch((error) => {
                    this.error = 'Unable to get agents calendar'
                })
        },
        updateSME(sme) {
            this.selectedSME = sme
        },
        confirmSME(){
            // console.log('emiting confirmed', {Agent: this.selectedSME.title, AgentCode: this.selectedSME.agentcode})
            this.$emit('sme-confirmed', {Agent: this.selectedSME.title, AgentCode: this.selectedSME.agentcode})
        },
    },

    computed: {
        selectedAgent(){
            return this.selectedSME ? this.selectedSME.title : '***'
        }
    },
}
</script>