<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-clients-submenu></q-my-clients-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <q-form-entries-data-table title="Financial Information Form Data" :slug="slug" custom-form-url="/newbusiness/fif-form"></q-form-entries-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import moment from 'moment'
import QFormEntriesDataTable from '@/FML/data/components/QFormEntriesDataTable';
import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue';
export default {
    props: [],
    data: () => ({
        loaded: false,
        slug: 'financial-information-form'
    }),
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QFormEntriesDataTable,
        QMyClientsSubmenu
    },
}
</script>
<style scoped>
</style>