<template>
    <v-container class="text-left">
        <v-row>
            <v-col>
                <v-btn color="primary" target="_blank" :href="QRSCalendarLink" @click="Unconfirmed = false" :disabled="!Unconfirmed">
                    <v-icon left>icon-events</v-icon>
                    Set an appointment
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="success" @click="confirmAppointment" :disabled="Unconfirmed">Confirmed that appointment is set with the QRS Team</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {

    data(){
        return {
            Unconfirmed: true,
            QRSCalendarLink: 'https://crm.zoho.com/bookings/ComplimentaryRetirementReview?rid=4bcc084be3339bacdbff0402c142f0849e180364eed5aec06f40cddb9c00c61agid5195e291751e8227009907c164f92e6747d0d9f4bab5a6c9cfb55329ca2deeca',
        }
    },
    methods: {
        confirmAppointment() {
            // console.log('confirming qrs')
            this.$emit('qrs-confirmed')
        }
    }
}
</script>