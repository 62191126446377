
import { defineComponent, PropType } from 'vue'

import { FullWidthTextBlock, SummitLightSpeedVideoBlock, SummitWistiaVideoBlock, SummitQuizBlock } from '@/CMS/types/blocks'

import QSummitFullScreenLayout from './layouts/FullScreen.vue'
import QSummitStripedLayout from './layouts/Striped.vue'
import QSummitWistiaPlayer from '@/CMS/views/ContentBlocks/Elements/QSummitWistiaPlayer.vue'
import QSummitLightSpeedPlayer from '@/CMS/views/ContentBlocks/Elements/QSummitLightSpeedPlayer.vue'
import QSummitQuiz from '@/FML/views/index-quiz.vue'


export default defineComponent({
    props: {
        step: { type: Object as PropType<{
            StepConfig: {
                content: SummitLightSpeedVideoBlock | SummitWistiaVideoBlock | FullWidthTextBlock | SummitQuizBlock
            },
            StepType: string,
        }>, required: true },
    },

    components: {
        QSummitFullScreenLayout,
        QSummitStripedLayout,
        QSummitWistiaPlayer,
        QSummitLightSpeedPlayer,
        QSummitQuiz,
    },

    computed: {
        layout() {
           return this.step.StepType === 'Skill Block' ? 'QSummitStripedLayout' : 'QSummitFullScreenLayout'
        },
        content_type() {
            return this.step.StepConfig.content.type
        }
    },

    methods: {
        isNoText(text: string) {
            return !text || text === '<p></p>'
        }
    },
})
