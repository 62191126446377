export default {
    'required': v => !!v || 'Required',
    'email': v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    'zip': v => /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) || 'Enter a valid zipcode', //https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
    'date': v => moment(v, 'MM-DD-YYYY').isValid() || 'Enter a valid date',
    'phone': function(v) {
        if (typeof v == 'undefined') {
            return true
        }
        if (v == null || v == '') {
            return true
        }
        if (typeof v == 'string') {
            v = v.replaceAll("-", "")
            if (v != null && (Number.isInteger(Number(v)) && v.length >= 10)) {
                return true
            }
        }
        return 'Please enter a valid phone number in the phone field.'
    },
    'not_upper_case': function(v) {
        if (typeof v == 'undefined') {
            return true
        }
        if (v == null || v.length <= 2) {
            return true;
        }
        if (v == null || v == '') {
            return true
        }
        if (v !== v.toString().toUpperCase()) {
            return true;
        }
        return 'Please do not use all upper case.';
    },
    'no_special_characters': function(v) {
        if (v == null || typeof v == 'undefined') {
            return true
        }

        // make sure we're working with a string
        const str = v.toString()
        const matches = str.match(/([a-z]|[A-Z]|[']|[-]|[\s])/g) || []

        if (str.length == matches.length) {
            return true
        }

        return 'Please do not add any special characters.'
    },
    'valid_name_characters': function(v) {
        if (v == null || typeof v == 'undefined') {
            return true
        }

        // make sure we're working with a string
        const str = v.toString()
        const matches = str.match(/([a-z]|[A-Z]|[']|[-]|[\s]|[&])/g) || []

        if (str.length == matches.length) {
            return true
        }

        return 'Please do not add any special characters.'
    },
    'number': function(v) {
        if (typeof v == 'undefined' || v == null || v == '') {
            return true
        }
        v = Number(v);
        if (typeof v === 'number' && isFinite(v)) {
            return true;
        }
        return 'Enter a number';
    },
    'int': function(v) {
        if (typeof v == 'undefined' || v == null || v == '') {
            return true
        }
        if (Number.isInteger(v)) {
            return true;
        }
        return 'Enter a whole number';
    },
    'nullable_email': function(v) {
        if(!v) {
            return true
        }

        return /.+@.+\..+/.test(v) || 'E-mail must be valid'
    }
}
