<template>
    <div class="text-left">
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" id="audit_datatable">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 ml-5 white--text">{{ title }}
                            <v-btn dark icon @click="refreshData">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <!-- <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                            
                            <v-btn text small color="q_leads_4" @click="exportResults">
                                <v-icon small left>fas fa-file-export</v-icon> Export Results
                            </v-btn>
                            //
                        </p> -->
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table dense :mobile-breakpoint="2" :items-per-page.sync="rows" v-model="selected" :headers="the_headers" :items="all_data" class="elevation-0 nowrap" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="data_loading" :server-items-length="total_items" :options.sync="options">
                <template v-slot:item.ID="{ item }">
                    <v-btn small @click="viewResponse(item)">View</v-btn>
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan="12">
                            <v-btn @click="downloadData">Export</v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "@/components/datatables/QDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
const { Parser } = require('json2csv');

export default {
    name: "QFormEntriesDataTable",
    props: ['title', 'slug', 'agentCode', 'defaultSortColumn', 'defaultFilterKeys', 'defaultFilters', 'customFormUrl'],
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: this.defaultFilters ? this.defaultFilters : {},
            the_data: [],
            data_loading: false,
            total_num: 0,
            options: {
                'sortBy': this.defaultSortColumn ? [this.defaultSortColumn] : ['LastChangeDate'],
                'sortDesc': [true],
                itemsPerPage: 50
            },
            entity: 'audit',
            agent_code: null,
            the_available_filters: null,
            columns: [],
            always_show: ["ID", "Action", "AgentCode", "AgentName", "LastChangeBy", "LastChangeDate", "OrderNumber"]
        }
    },
    mounted: function() {
        this.loadData(this.slug)
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            var d = [];
            var g = this
            this.the_data.forEach(function(response) {
                var row = {
                    ID: response.ID
                }
                if (typeof response.AgentName != 'undefined') {
                    row.AgentName = response.AgentName;
                    row.AgentCode = response.AgentCode;
                }
                if (typeof response.Lead != 'undefined') {
                    row.Lead = response.Lead.FirstName + " " + response.Lead.LastName;
                    row.LeadCode = response.Lead.LeadCode;
                }
                Object.keys(response.FormResponseAnswers).forEach(function(key) {
                    var answer = response.FormResponseAnswers[key];
                    var col = !answer.useQuestionAsLabel ? g.slugify(answer.FormQuestionID + "_" + answer.FormQuestionTitle) : g.slugify(answer.FormQuestionID + "_" + answer.FormQuestionLabel)
                    if (answer.AnswerDataType == 'JSON') {
                        if (answer.formatAsCurrency === true) {
                            row[col] = answer.AnswerValue != null ? g.formatCurrency(answer.AnswerValue) : ''
                        } else {
                            row[col] = answer.AnswerValue != null ? answer.AnswerValue.substring(0, 20) : ''
                        }

                    } else {
                        if (answer.formatAsCurrency === true) {
                            row[col] = g.formatCurrency(answer.AnswerContent)
                        } else {
                            row[col] = answer.AnswerContent
                        }

                    }
                })
                d.push(row);
            })
            return d;
        },
        filter_keys: function() {
            if (this.defaultFilterKeys) {
                return this.defaultFilterKeys;
            }
            return ['LastChangeBy', 'Action', 'Division'];
        },
        total_items: function() {
            return this.total_num;
        },
        available_filters: function() {
            return this.the_available_filters;
        },
        the_headers: function() {
            //go through all the available data and get a list of columns.
            var columns = [{
                'text': "",
                'value': "ID",
                'sortable': false,
            }];
            var has_lead = false;
            var has_agent = false;
            var g = this
            this.the_data.forEach(function(response) {
                if (typeof response.AgentName != 'undefined' && !has_agent) {
                    columns.push({
                        'text': "Agent Name",
                        'value': "AgentName",
                        'sortable': false,
                    })
                    columns.push({
                        'text': "AgentCode",
                        'value': "AgentCode",
                        'sortable': false,
                    })
                    has_agent = true;
                }
                if (typeof response.Lead != 'undefined' && !has_lead) {
                    columns.push({
                        'text': "Lead",
                        'value': "Lead",
                        'sortable': false,
                    })
                    columns.push({
                        'text': "Lead Code",
                        'value': "LeadCode",
                        'sortable': false,
                    })
                    has_lead = true
                }
            })
            if (this.the_data.length > 0) {
                Object.keys(this.the_data[0].FormResponseAnswers).forEach(function(key) {
                    var question = g.the_data[0].FormResponseAnswers[key]
                    columns.push({
                        'text': !question.useQuestionAsLabel ? question.FormQuestionTitle : question.FormQuestionLabel,
                        'value': !question.useQuestionAsLabel ? g.slugify(question.FormQuestionID + "_" + question.FormQuestionTitle) : g.slugify(question.FormQuestionID + "_" + question.FormQuestionLabel),
                        'sortable': false,
                    })
                })
            }
            return columns;
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI.getFormDataWithSlug(this.slug, this.filters, { ...opt }, this.agentCode).then(function(json) {
                g.data_loading = false
                g.$set(g, 'total_num', json.meta.total);
                if (typeof json.meta.filters != 'undefined') {
                    g.$set(g, 'the_available_filters', json.meta.filters);
                }
                g.$nextTick(function() {
                    g.$set(g, 'the_data', json.data);
                })
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200),
        viewResponse: function(item) {
            if (this.customFormUrl) {
                this.$router.push({ path: this.customFormUrl, query: { 'form_response_id': item.ID } });
                return
            }
            this.$router.push({ name: 'FormView', params: { 'form_slug': this.slug }, query: { 'form_response_id': item.ID } });
        },
        downloadData: debounce(function() {
            var opt = {
                ...this.options,
                search: this.search,
            }
            var endpoint = '/api/private/forms/slug/' + this.slug + "/data";
            var params = {
                ...opt,
                ...this.filters,
                'itemsPerPage': "csv",
            }
            this.dispatchJob({
                'method': "GET",
                'path': endpoint,
                'params': params,
                'JobName': this.title + " Export"
            })
        }, 200),
    },
    watch: {
        'agentCode': function(newV, oldV) {
            this.$nextTick(this.refreshData);
        },
        'slug': function(newV, oldV) {
            if (typeof newV == 'undefined' || newV == null || newV == '') {
                return
            }
            this.$nextTick(this.refreshData);
        },
    },
    components: {}
}
</script>
<style>
.smaller-text.v-data-table td {
    font-size: .65em;
}

.smaller-text.v-data-table td {
    padding: 0px 8px 0px;
}

.smaller-text.v-data-table--dense td {}
</style>