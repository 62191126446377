<!--
this component is used in Pending Policy
-->
<template>
    <q-submenu :title="title" :titlelink="[]" :baseUrl="''" :buttons="buttons" :submenu="submenu" class="q-stycky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    props: ["hasDemo"],
    data() {
        return {
            title: "My Clients",

        }
    },
    computed: {
        submenu: function() {
            var g = this;
            var submenu = [{
                label: "Help",
                to: "/support"
            }, ];
            if (this.hasDemo) {
                submenu.push({
                    label: "Start Demo",
                    fun: function() {
                        g.$emit("demo");
                    }
                })
            }
            return submenu;
        },
        'buttons': function() {
            var b = [
            {
                label: "Applications",
                to: "/new_business/applications",
                icon: "fas fa-arrow-alt-circle-right",
            },
            {
                label: "Placed Policies",
                to: "/new_business/placed_policy",
                icon: "fas fa-arrow-alt-circle-right",
            },
            {
                label: "Pending Policies",
                to: "/new_business/pending_policy",
                icon: "fas fa-arrow-alt-circle-right",
            },
            ]
            if (this.hasPermission('agent:pcs_permission')) {
                b.push({
                    label: "PCS Bonus",
                    to: "/new_business/commission/",
                    icon: "fas fa-arrow-alt-circle-right",
                });
            }
            if (this.hasPermission('use:FIForm') || this.hasPermission('agent:use_fifform')) {
                b.push({
                    label: "FIF Form",
                    to: "/newbusiness/fif-form",
                    icon: "fas fa-arrow-alt-circle-right",
                });
                b.push({
                    label: "FIF Data",
                    to: "/newbusiness/fif-form/data",
                    icon: "fas fa-arrow-alt-circle-right",
                });
            }
            return b;
        }
    },
    components: {
        QSubmenu
    },
    methods: {},
}
</script>
<style>
</style>