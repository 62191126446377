<template>
    <v-card class="q-fif-questions">
        <v-card-title v-text="cardTitle"/>

        <v-card-text>
            <template v-if="surveyResult">
                <p v-for="m in surveyResult.message.split('\n').map((s) => s.trim()).filter(Boolean)" :key="m">
                    {{ m }}
                </p>

                <p v-if="surveyResult.button === 'OPEN_CALENDAR'">
                    <span class="red--text">NOTE:</span> After setting the appointment you must return here to confirm.
                </p>
            </template>

            <FIFSurvey v-else :questions="currentScriptQuestions" @finish="onFinish" />
        </v-card-text>

        <v-card-actions v-if="surveyResult" class="text-right">
            <FIFOpenCalendar v-on="$listeners" v-if="surveyResult.button === 'OPEN_CALENDAR'" />
            <FIFSetAnAppointment v-on="$listeners" v-if="surveyResult.button === 'SET_AN_APPOINTMENT'" />
        </v-card-actions>
    </v-card>
</template>

<script>
import { questions } from './questions'
import FIFSurvey from './FIFSurvey.vue'
import FIFOpenCalendar from './Buttons/OpenCalendar.vue'
import FIFSetAnAppointment from './Buttons/SetAnAppointment.vue'

export default {
    components: { FIFSurvey, FIFOpenCalendar, FIFSetAnAppointment },

    props: {
        scripts: {
            type: Array
        },
    },

    data() {
        return {
            currentScriptIndex: 0,
            surveyResult: '',
            smeAgent: null,
        }
    },

    computed: {
        currentScript() {
            return this.scripts[this.currentScriptIndex]
        },
        currentScriptQuestions() {
            return questions[this.currentScript]
        },
        cardTitle() {
            return {
                'DFL': 'Debt Free Life',
                'QRS': 'Quility Retirement Services'
            }[this.currentScript]
        },

    },

    methods: {
        onFinish(answers) {
            // See: https://quility.atlassian.net/jira/software/projects/DEVST/boards/12?selectedIssue=DEVST-1695
            if (this.currentScript === 'DFL') {
                if (answers.some((a) => a.answer === true)) {
                    this.surveyResult = {
                        message: `
                        We have an entire division solely based on education around debt elimination and our Debt Free Life program, they are SUBJECT MATTER EXPERTS. They can give you the education, and you can do whatever you want with that information, but I KNOW you are going to be happy with what you hear. They have helped so many of my clients.

                        Let's look at their calendar and see when they are available to meet...
                        `,
                        button: 'OPEN_CALENDAR'
                    }
                } else {
                    if (this.scripts.length == 1) {
                        this.surveyResult = {
                            message: `Thank you for your time. Please let me know if anything changes and we can review your position.`,
                        }
                    } else {
                        this.currentScriptIndex += 1
                    }
                }
            }

            if (this.currentScript === 'QRS') {
                if (answers.some((a) => a.answer === true)) {
                    this.surveyResult = {
                        message: `
                        We have an entire division solely based on education around retirement. Quility Financial Advisors are SUBJECT MATTER EXPERTS and they can educate you on your retirement accounts and help you fill in any gaps you might have. You can do whatever you want with that information, but I KNOW you are going to be happy with the information. They have helped out so many of my clients.

                        Let’s look at their calendar and see when they are available to meet…
                        `,
                        button: 'SET_AN_APPOINTMENT'
                    }
                } else {
                    this.surveyResult = {
                        message: `At this time mortgage protection is the best solution for your client. We will review the file in a year for the PCS program`,
                    }
                }
            }

            if (this.currentScript === 'IUL') {
                if (answers.some((a) => a.answer === true)) {
                    this.surveyResult = {
                        message: `
                        We have an entire division solely based on education around retirement and our Tax Free Retirement program. Quility Financial Advisors are SUBJECT MATTER EXPERTS in tax free retirement. They can give you the education and you can do what ever you want with that information, but I KNOW you are going to be happy with the information.

                        Let’s look at their calendar and see when they are available to meet…
                        `,
                        button: 'SET_AN_APPOINTMENT'
                    }
                } else {
                    this.surveyResult = {
                        message: `Thank you for your time. Please let me know if anything changes and we can review your position.`,
                    }
                }
            }
        },
    },

    watch: {
        smeAgent: function(v) {
            console.log('sme agent', v)
        }
    }
}
</script>

<style lang="scss" scoped>
  p:last-child {
    margin-bottom: 0;
  }
</style>