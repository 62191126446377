<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <div class="container header">
            <h1>fontello <small>font demo</small></h1>
            <label class="switch">
                <input type="checkbox" onclick="toggleCodes(this.checked)">show codes
            </label>
        </div>
        <div class="container" id="icons">
            <div class="row">
        <div class="span3" title="Code: 0xe800">
          <i class="demo-icon icon-q-dfl-icon">&#xe800;</i> <span class="i-name">icon-q-dfl-icon</span><span class="i-code">0xe800</span>
        </div>
        <div class="span3" title="Code: 0xe801">
          <i class="demo-icon icon-q-search">&#xe801;</i> <span class="i-name">icon-q-search</span><span class="i-code">0xe801</span>
        </div>
        <div class="span3" title="Code: 0xe802">
          <i class="demo-icon icon-q-engage">&#xe802;</i> <span class="i-name">icon-q-engage</span><span class="i-code">0xe802</span>
        </div>
        <div class="span3" title="Code: 0xe803">
          <i class="demo-icon icon-q-stats-select">&#xe803;</i> <span class="i-name">icon-q-stats-select</span><span class="i-code">0xe803</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe804">
          <i class="demo-icon icon-q-leads-select">&#xe804;</i> <span class="i-name">icon-q-leads-select</span><span class="i-code">0xe804</span>
        </div>
        <div class="span3" title="Code: 0xe805">
          <i class="demo-icon icon-q-rx">&#xe805;</i> <span class="i-name">icon-q-rx</span><span class="i-code">0xe805</span>
        </div>
        <div class="span3" title="Code: 0xe806">
          <i class="demo-icon icon-q-summit-icon-select">&#xe806;</i> <span class="i-name">icon-q-summit-icon-select</span><span class="i-code">0xe806</span>
        </div>
        <div class="span3" title="Code: 0xe807">
          <i class="demo-icon icon-q-summit-icon">&#xe807;</i> <span class="i-name">icon-q-summit-icon</span><span class="i-code">0xe807</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe808">
          <i class="demo-icon icon-q-benefits">&#xe808;</i> <span class="i-name">icon-q-benefits</span><span class="i-code">0xe808</span>
        </div>
        <div class="span3" title="Code: 0xe809">
          <i class="demo-icon icon-q-carriers-products-select">&#xe809;</i> <span class="i-name">icon-q-carriers-products-select</span><span class="i-code">0xe809</span>
        </div>
        <div class="span3" title="Code: 0xe80a">
          <i class="demo-icon icon-q-marketing-select">&#xe80a;</i> <span class="i-name">icon-q-marketing-select</span><span class="i-code">0xe80a</span>
        </div>
        <div class="span3" title="Code: 0xe80b">
          <i class="demo-icon icon-q-qtech-select">&#xe80b;</i> <span class="i-name">icon-q-qtech-select</span><span class="i-code">0xe80b</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe80c">
          <i class="demo-icon icon-switchboard-select">&#xe80c;</i> <span class="i-name">icon-switchboard-select</span><span class="i-code">0xe80c</span>
        </div>
        <div class="span3" title="Code: 0xe80d">
          <i class="demo-icon icon-switchboard">&#xe80d;</i> <span class="i-name">icon-switchboard</span><span class="i-code">0xe80d</span>
        </div>
        <div class="span3" title="Code: 0xe80e">
          <i class="demo-icon icon-myquility-select">&#xe80e;</i> <span class="i-name">icon-myquility-select</span><span class="i-code">0xe80e</span>
        </div>
        <div class="span3" title="Code: 0xe80f">
          <i class="demo-icon icon-q-dfl-icon-circle">&#xe80f;</i> <span class="i-name">icon-q-dfl-icon-circle</span><span class="i-code">0xe80f</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe813">
          <i class="demo-icon icon-q-training">&#xe813;</i> <span class="i-name">icon-q-training</span><span class="i-code">0xe813</span>
        </div>
        <div class="span3" title="Code: 0xe816">
          <i class="demo-icon icon-q-shopping">&#xe816;</i> <span class="i-name">icon-q-shopping</span><span class="i-code">0xe816</span>
        </div>
        <div class="span3" title="Code: 0xe81a">
          <i class="demo-icon icon-q-v-mentor">&#xe81a;</i> <span class="i-name">icon-q-v-mentor</span><span class="i-code">0xe81a</span>
        </div>
        <div class="span3" title="Code: 0xe81e">
          <i class="demo-icon icon-events">&#xe81e;</i> <span class="i-name">icon-events</span><span class="i-code">0xe81e</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe81f">
          <i class="demo-icon icon-q-engage-select">&#xe81f;</i> <span class="i-name">icon-q-engage-select</span><span class="i-code">0xe81f</span>
        </div>
        <div class="span3" title="Code: 0xe821">
          <i class="demo-icon icon-q-home-select">&#xe821;</i> <span class="i-name">icon-q-home-select</span><span class="i-code">0xe821</span>
        </div>
        <div class="span3" title="Code: 0xe823">
          <i class="demo-icon icon-q-home">&#xe823;</i> <span class="i-name">icon-q-home</span><span class="i-code">0xe823</span>
        </div>
        <div class="span3" title="Code: 0xe824">
          <i class="demo-icon icon-q-leads">&#xe824;</i> <span class="i-name">icon-q-leads</span><span class="i-code">0xe824</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe825">
          <i class="demo-icon icon-q-logout-select">&#xe825;</i> <span class="i-name">icon-q-logout-select</span><span class="i-code">0xe825</span>
        </div>
        <div class="span3" title="Code: 0xe826">
          <i class="demo-icon icon-q-new-business">&#xe826;</i> <span class="i-name">icon-q-new-business</span><span class="i-code">0xe826</span>
        </div>
        <div class="span3" title="Code: 0xe827">
          <i class="demo-icon icon-q-logout">&#xe827;</i> <span class="i-name">icon-q-logout</span><span class="i-code">0xe827</span>
        </div>
        <div class="span3" title="Code: 0xe828">
          <i class="demo-icon icon-q-new-business-select">&#xe828;</i> <span class="i-name">icon-q-new-business-select</span><span class="i-code">0xe828</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe829">
          <i class="demo-icon icon-q-resources-select">&#xe829;</i> <span class="i-name">icon-q-resources-select</span><span class="i-code">0xe829</span>
        </div>
        <div class="span3" title="Code: 0xe82a">
          <i class="demo-icon icon-q-resources">&#xe82a;</i> <span class="i-name">icon-q-resources</span><span class="i-code">0xe82a</span>
        </div>
        <div class="span3" title="Code: 0xe82b">
          <i class="demo-icon icon-q-search-select">&#xe82b;</i> <span class="i-name">icon-q-search-select</span><span class="i-code">0xe82b</span>
        </div>
        <div class="span3" title="Code: 0xe82c">
          <i class="demo-icon icon-q-email">&#xe82c;</i> <span class="i-name">icon-q-email</span><span class="i-code">0xe82c</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe82d">
          <i class="demo-icon icon-q-email-select">&#xe82d;</i> <span class="i-name">icon-q-email-select</span><span class="i-code">0xe82d</span>
        </div>
        <div class="span3" title="Code: 0xe82e">
          <i class="demo-icon icon-q-support-select">&#xe82e;</i> <span class="i-name">icon-q-support-select</span><span class="i-code">0xe82e</span>
        </div>
        <div class="span3" title="Code: 0xe82f">
          <i class="demo-icon icon-q-stats">&#xe82f;</i> <span class="i-name">icon-q-stats</span><span class="i-code">0xe82f</span>
        </div>
        <div class="span3" title="Code: 0xe830">
          <i class="demo-icon icon-q-support">&#xe830;</i> <span class="i-name">icon-q-support</span><span class="i-code">0xe830</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe831">
          <i class="demo-icon icon-q-training-select">&#xe831;</i> <span class="i-name">icon-q-training-select</span><span class="i-code">0xe831</span>
        </div>
        <div class="span3" title="Code: 0xe832">
          <i class="demo-icon icon-q-benefits-select">&#xe832;</i> <span class="i-name">icon-q-benefits-select</span><span class="i-code">0xe832</span>
        </div>
        <div class="span3" title="Code: 0xe833">
          <i class="demo-icon icon-q-agency">&#xe833;</i> <span class="i-name">icon-q-agency</span><span class="i-code">0xe833</span>
        </div>
        <div class="span3" title="Code: 0xe834">
          <i class="demo-icon icon-q-agency-select">&#xe834;</i> <span class="i-name">icon-q-agency-select</span><span class="i-code">0xe834</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe835">
          <i class="demo-icon icon-q-carriers-products">&#xe835;</i> <span class="i-name">icon-q-carriers-products</span><span class="i-code">0xe835</span>
        </div>
        <div class="span3" title="Code: 0xe836">
          <i class="demo-icon icon-q-travel">&#xe836;</i> <span class="i-name">icon-q-travel</span><span class="i-code">0xe836</span>
        </div>
        <div class="span3" title="Code: 0xe837">
          <i class="demo-icon icon-q-marketing">&#xe837;</i> <span class="i-name">icon-q-marketing</span><span class="i-code">0xe837</span>
        </div>
        <div class="span3" title="Code: 0xe838">
          <i class="demo-icon icon-q-onboarding">&#xe838;</i> <span class="i-name">icon-q-onboarding</span><span class="i-code">0xe838</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe839">
          <i class="demo-icon icon-q-qtech">&#xe839;</i> <span class="i-name">icon-q-qtech</span><span class="i-code">0xe839</span>
        </div>
        <div class="span3" title="Code: 0xe83a">
          <i class="demo-icon icon-q-rx-select">&#xe83a;</i> <span class="i-name">icon-q-rx-select</span><span class="i-code">0xe83a</span>
        </div>
        <div class="span3" title="Code: 0xe83b">
          <i class="demo-icon icon-q-everplans">&#xe83b;</i> <span class="i-name">icon-q-everplans</span><span class="i-code">0xe83b</span>
        </div>
        <div class="span3" title="Code: 0xe83c">
          <i class="demo-icon icon-q-oc">&#xe83c;</i> <span class="i-name">icon-q-oc</span><span class="i-code">0xe83c</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe83d">
          <i class="demo-icon icon-q-dialer">&#xe83d;</i> <span class="i-name">icon-q-dialer</span><span class="i-code">0xe83d</span>
        </div>
        <div class="span3" title="Code: 0xe83e">
          <i class="demo-icon icon-myquility">&#xe83e;</i> <span class="i-name">icon-myquility</span><span class="i-code">0xe83e</span>
        </div>
        <div class="span3" title="Code: 0xe83f">
          <i class="demo-icon icon-q-v-assistant">&#xe83f;</i> <span class="i-name">icon-q-v-assistant</span><span class="i-code">0xe83f</span>
        </div>
        <div class="span3" title="Code: 0xe840">
          <i class="demo-icon icon-quility-u">&#xe840;</i> <span class="i-name">icon-quility-u</span><span class="i-code">0xe840</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe841">
          <i class="demo-icon icon-q-agency-building">&#xe841;</i> <span class="i-name">icon-q-agency-building</span><span class="i-code">0xe841</span>
        </div>
        <div class="span3" title="Code: 0xe842">
          <i class="demo-icon icon-q-agency-building-select">&#xe842;</i> <span class="i-name">icon-q-agency-building-select</span><span class="i-code">0xe842</span>
        </div>
        <div class="span3" title="Code: 0xe845">
          <i class="demo-icon icon-q-contests-inactive">&#xe845;</i> <span class="i-name">icon-q-contests-inactive</span><span class="i-code">0xe845</span>
        </div>
        <div class="span3" title="Code: 0xe846">
          <i class="demo-icon icon-q-contests-active">&#xe846;</i> <span class="i-name">icon-q-contests-active</span><span class="i-code">0xe846</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe847">
          <i class="demo-icon icon-q-news-inactive">&#xe847;</i> <span class="i-name">icon-q-news-inactive</span><span class="i-code">0xe847</span>
        </div>
        <div class="span3" title="Code: 0xe848">
          <i class="demo-icon icon-q-news-select">&#xe848;</i> <span class="i-name">icon-q-news-select</span><span class="i-code">0xe848</span>
        </div>
        <div class="span3" title="Code: 0xe849">
          <i class="demo-icon icon-q-content-editor">&#xe849;</i> <span class="i-name">icon-q-content-editor</span><span class="i-code">0xe849</span>
        </div>
        <div class="span3" title="Code: 0xe84a">
          <i class="demo-icon icon-q-content-editor-select">&#xe84a;</i> <span class="i-name">icon-q-content-editor-select</span><span class="i-code">0xe84a</span>
        </div>
      </div>
      <div class="row">
        <div class="span3" title="Code: 0xe84b">
          <i class="demo-icon icon-connect-accounts">&#xe84b;</i> <span class="i-name">icon-connect-accounts</span><span class="i-code">0xe84b</span>
        </div>
        <div class="span3" title="Code: 0xe84d">
          <i class="demo-icon icon-appthumbs_ipipeline">&#xe84d;</i> <span class="i-name">icon-appthumbs_ipipeline</span><span class="i-code">0xe84d</span>
        </div>
        <div class="span3" title="Code: 0xe84e">
          <i class="demo-icon icon-q-formpipe">&#xe84e;</i> <span class="i-name">icon-q-formpipe</span><span class="i-code">0xe84e</span>
        </div>
        <div class="span3" title="Code: 0xe84f">
          <i class="demo-icon icon-ipipeline">&#xe84f;</i> <span class="i-name">icon-ipipeline</span><span class="i-code">0xe84f</span>
        </div>
      </div>
        </div>
        <div class="container footer">Generated by <a href="https://fontello.com">fontello.com</a></div>
        </div>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            //search: '',
        }
    },
    computed: {
        toggleCodes(on) {
            var obj = document.getElementById('icons');
            if (on) {
                obj.className += ' codesOn';
            } else {
                obj.className = obj.className.replace(' codesOn', '');
            }
        }

    },
    components: {

    },
    methods: {},
}

</script>
<style scoped>
/*html {
font-size: 100%;
-webkit-text-size-adjust: 100%;
-ms-text-size-adjust: 100%;
}*/
a:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

a:hover,
a:active {
    outline: 0;
}

input {
    margin: 0;
    font-size: 100%;
    vertical-align: middle;
    *overflow: visible;
    line-height: normal;
}

input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

/*body {
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    background-color: #fff;
}*/

a {
    color: #08c;
    text-decoration: none;
}

a:hover {
    color: #005580;
    text-decoration: underline;
}

.row {
    margin-left: -20px;
    *zoom: 1;
}

/*.row:before,
.row:after {
display: table;
content: "";
line-height: 0;
}
*/
.row:after {
    clear: both;
}

.span3 {
    float: left;
    min-height: 1px;
    margin-left: 20px;
    width: 22%;
    padding-top: 30px;
}

.container {
    width: 940px;
    max-width: 95%;
    margin: 0 auto;
    *zoom: 1;
}

.container:before,
.container:after {
    display: table;
    content: "";
    line-height: 0;
}

.container:after {
    clear: both;
}

small {
    font-size: 85%;
}

h1 {
    margin: 10px 0;
    font-family: inherit;
    font-weight: bold;
    line-height: 20px;
    color: inherit;
    text-rendering: optimizelegibility;
    line-height: 40px;
    font-size: 38.5px;
}

h1 small {
    font-weight: normal;
    line-height: 1;
    color: #999;
    font-size: 24.5px;
}

/*body {
margin-top: 90px;
}*/
/*.header {
position: fixed;
top: 0;
left: 50%;
margin-left: -480px;
background-color: #fff;
border-bottom: 1px solid #ddd;
padding-top: 10px;
z-index: 10;
}*/
.footer {
    color: #ddd;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
}

.footer a {
    color: #ccc;
    text-decoration: underline;
}

.the-icons {
    font-size: 14px;
    line-height: 24px;
}

.switch {
    position: absolute;
    right: 0;
    bottom: 10px;
    color: #666;
}

.switch input {
    margin-right: 0.3em;
}

.i-name {
    display: block;
    font-size: .85em;
}


.codesOn .i-code {
    display: inline;
}

.i-code {
    display: none;
}

[class^=icon-]:before,
[class*=" icon-"]:before {
    display: none;
}

@font-face {
    font-family: 'fontello';
    src: url('./font/fontello.eot?22788827');
    src: url('./font/fontello.eot?22788827#iefix') format('embedded-opentype'),
        url('./font/fontello.woff?22788827') format('woff'),
        url('./font/fontello.ttf?22788827') format('truetype'),
        url('./font/fontello.svg?22788827#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

.demo-icon {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    font-size: 1.7em;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* You can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

</style>
