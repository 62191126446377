const radio = (title, to, answer) => ({ title, type: 'radio', to, answer })
const input = (title, to, answer) => ({ title, type: 'input', to, answer })
const button = (title, to, answer) => ({ title, type: 'button', to, answer })

export const questions = {
    DFL: [
        {
            title: 'Do you feel like your debt is preventing you from contributing to retirement?',
            answers: [radio('Yes', -1, true), radio('No', 1, false)]
        },
        {
            title: 'If your mortgage was paid off, would your retirement be more comfortable?',
            answers: [radio('Yes', -1, true), radio('No', 2, false)]
        },
        {
            title: 'If you had no debt, do you think you could retire sooner?',
            answers: [radio('Yes', -1, true), radio('No', 3, false)]
        },
    ],
    QRS: [
        { // 0
            title: 'Has your current advisor shown you what your income gap will be when you retire?',
            answers: [radio('Yes', 1), radio('No', 2)]
        },
        { // 1
            title: 'Okay great, what is it approximately?',
            answers: [input('Income gap', 3), button('can’t provide an exact amount', 2)]
        },
        { // 2
            title: 'Is it better to find out what that “gap” is now or when you retire?',
            answers: [radio('now', -1, true), radio('when I retire', 3)]
        },

        { // 3
            title: 'Do you know how long your retirement assets are projected to last?',
            answers: [radio('Yes', 4), radio('No', 5)],
        },
        { // 4
            title: 'Okay great, what is it approximately?',
            answers: [input('Number of years assets will last', 6, true), button('can’t provide an exact amount', 5)]
        },
        { // 5
            title: 'Is it better to find out now or when it might be too late?',
            answers: [radio('now', -1, true), radio('too late', 6)]
        },

        { // 6
            title: 'Do you know how much your retirement income will decrease when the market crashes?',
            answers: [radio('Yes', 7), radio('No', 8)]
        },
        { // 7
            title: 'Okay great, how much will it decrease?',
            answers: [input('', -1), button('can’t provide an exact amount', 8)]
        },
        { // 8
            title: 'Is it better to find out now or when it might be too late?',
            answers: [radio('now', -1, true), radio('too late', -1, false)]
        }
    ]
}