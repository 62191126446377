<template>
    <QSummitStepContainer class="q-summit-step-title" :class="{ 'q-summit-step-title--mobile': $vuetify.breakpoint.smAndDown }">
        <div class="q-summit-step-title__badge" v-if="type === 'Milestone' && step.StepIcon">
            <QSummitSidebarGuideIcon height="150px" :url="step.StepIcon" :step="step" />
        </div>
        <div class="q-summit-step-title__row">
            <div class="q-summit-step-title__left">
                <div class="q-summit-step-title__text">
                    <div class="q-summit-step-title__type">
                        <template v-if="type === 'Step'">
                            Step {{ Number(step.StepCategoryOrder) + 1 }}
                        </template>
                        <template v-else-if="type === 'Checkpoint'">
                            <QSummitSidebarGuideIcon v-if="step.StepIcon" height="24px" :url="step.StepIcon" :step="step" />
                            <span>Mentor checkpoint</span>
                        </template>
                        <template v-else-if="type === 'Milestone'">
                            <span>Milestone</span>
                        </template>
                        <template v-else-if="type === 'Challenge'">
                            <span>Challenge</span>
                        </template>
                        <template v-else-if="type === 'Skill Block'">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="28" height="28">
                                <circle data-name="Ellipse 997" cx="20" cy="20" fill="#6ecf93" r="20" />
                                <path data-name="Path 74972" d="M18.01 5.84h7.77a1 1 0 0 1 .92 1.4l-4.27 10h5.67a1 1 0 0 1 .83 1.55L17.78 35.16a1 1 0 0 1-1.82-.74l1.88-10.76h-5a1 1 0 0 1-.96-1.31l5.18-15.83a1 1 0 0 1 .95-.68Zm6.26 2h-5.53L14.2 21.66h4.82a1 1 0 0 1 .99 1.18l-1.29 7.37 7.48-10.98h-5.3a1 1 0 0 1-.91-1.39Z" fill="#fff" /></svg>
                            <span>Skill Block</span>
                        </template>
                    </div>
                    <div class="q-summit-step-title__title">
                        {{ step.StepTitle }}
                    </div>
                </div>
            </div>
            <div class="q-summit-step-title__right">
                <QCompassProgress :currentStep="step.StepCategoryOrder" :totalSteps="step.StepsInCategory" class="q-summit-step-title__compass" />
            </div>
        </div>
    </QSummitStepContainer>
</template>
<script>
import QCompassProgress from "@/components/summit/QCompassProgress.vue";
import QSummitStepContainer from "./QSummitStepContainer.vue";
import QSummitSidebarGuideIcon from "../sidebar/guide/QSummitSidebarGuideIcon.vue";
import SummitNav from '@/store/Models/SummitNav';

export default {
    props: {
        step: { type: Object, required: true },
    },

    components: {
        QCompassProgress,
        QSummitStepContainer,
        QSummitSidebarGuideIcon
    },

    computed: {
        type() { return this.step.StepType },
        stepInProgress() {
            const steps = SummitNav
                .query()
                .orderBy('StepOrder')
                .get()
                .sort((a, b) => a.StepCategoryOrder - b.StepCategoryOrder)

            return steps.find((step) => step.CurrentStepInd) || steps[0]
        }
    }
}
</script>
<style lang="scss">
$padding-y: 3rem;

.q-summit-step-title {
    position: relative;
    padding-top: $padding-y;
    padding-bottom: $padding-y;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &__compass {
        max-height: 150px;
        max-width: 150px;
    }

    &__type {
        text-align: left;
        font: normal normal bold 25px/30px Montserrat;
        letter-spacing: 0.63px;
        display: flex;
        align-items: center;

        &>* {
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__title {
        text-align: left;
        font: normal normal 300 50px/65px Montserrat;
        letter-spacing: 0px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        width: 100%;
    }

    &__left {
        flex: 1;
    }

    &__badge {
        margin-right: 2rem;
    }

    &--mobile {
        flex-direction: column;
        position: relative;

        .q-summit-step-title {
            &__badge {
                margin-right: 0;

                &::after {
                    content: '';
                    background: #0c4463 0% 0% no-repeat padding-box;
                    width: 100%;
                    height: 50%;
                    position: absolute;
                    z-index: -1;
                    left: 0;
                    top: 0;
                    transform: translateY(-50%) translateY($padding-y);
                }
            }

            &__type {
                font: normal normal bold 15px/24px Montserrat;
            }

            &__title {
                font: normal normal 300 28px/36px Montserrat;
            }
        }
    }
}
</style>