<template>
    <v-container fluid class="grey lighten-5 my-0 px-0 py-0 q-fml-financial-inforamtion-form">
        <q-my-clients-submenu></q-my-clients-submenu>
        <v-row class="mx-3 mb-0">
            <v-col id="v-na-1" cols="12" class="pl-1 py-0 q_new_b_1">
                <p class="q-display-3 pl-5 py-2 white--text">
                    Financial Information Form
                    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                </p>
            </v-col>

            <v-row>
                <v-col cols="12" md="8" lg="12">
                    <v-expansion-panels popout mandatory v-model="step">
                        <v-expansion-panel>
                            <v-expansion-panel-header :color="step == 0 ? '#DDD' : ''" @click="step = 0">
                                <strong id="panel0">Client Contact
                                    <span v-if="lead">
                                        - {{lead.FirstName}} {{lead.LastName}} ({{lead.LeadCode}})
                                    </span>
                                </strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager>
                                <v-row>
                                    <v-col cols="12" md="6" lg="8">
                                        <q-lead-lookup v-model="lead" :return-object="true" label="Lead Lookup" :agent-code="agent_code"></q-lead-lookup>
                                    </v-col>
                                    <v-col class="d-flex justify-end align-center" cols="12" md="6" lg="4">
                                        <v-btn @click="create_referral_dialog = true">Add New Referral Lead</v-btn>
                                    </v-col>
                                    <v-col cols="12" v-if="lead">
                                        <p>{{lead.FirstName}} {{lead.LastName}} ({{lead.LeadCode}})</p>
                                        <p>{{lead.Street}}<br>{{lead.City}} {{lead.State}}, {{lead.Zip}}</p>
                                        <p>Phone: {{lead.Phone}}<br>Email: {{lead.Email}}</p>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel :disabled="loading || !lead == null">
                            <v-expansion-panel-header :color="step == 1 ? '#DDD' : ''" @click="step = 1">
                                <strong id="panel1">Assets</strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager>
                                <fml-question-set ref="assets_section" :hide-title="true" :slug="slug" question-set-index="0" v-model="response" v-on:input="logEvent" v-on:submitted="submittedEvent" v-on:saved="nextStep" :redirect-on-save="false"></fml-question-set>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel :disabled="loading || !lead == null">
                            <v-expansion-panel-header :color="step == 2 ? '#DDD' : ''" @click="step = 2">
                                <strong id="panel2">Income/Expenses</strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager>
                                <fml-question-set :hide-title="true" :slug="slug" question-set-index="1" v-model="response" v-on:input="logEvent" v-on:submitted="submittedEvent" v-on:saved="nextStep" :redirect-on-save="false">
                                    <template #right>
                                        <div style="width: 400px;">
                                            <h5>Totals</h5>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>Client 1 Income :</td>
                                                            <td>{{ formatCurrency(income_1_total) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Client 2 Income :</td>
                                                            <td>{{ formatCurrency(income_2_total) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Income :</td>
                                                            <td>{{ formatCurrency(income_total) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Expenses :</td>
                                                            <td>({{ formatCurrency(expenses_total) }})</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Income after Expenses :</td>
                                                            <td>{{ formatCurrency(income_total - expenses_total) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <br>
                                        </div>
                                    </template>
                                </fml-question-set>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel :disabled="loading || !lead == null">
                            <v-expansion-panel-header :color="step == 3 ? '#DDD' : ''" @click="step = 3">
                                <strong id="panel3">Debt</strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager>
                                <fml-question-set :hide-title="true" :slug="slug" question-set-index="2" v-model="response" v-on:input="logEvent" v-on:submitted="submittedEvent" v-on:saved="nextStep" :redirect-on-save="false"></fml-question-set>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel :disabled="loading || !lead == null">
                            <v-expansion-panel-header :color="step == 4 ? '#DDD' : ''" @click="step = 4">
                                <strong id="panel4">Results</strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div v-if="has_over_payment">
                                    <v-icon left>fas fa-check</v-icon>Client has over payment
                                </div>
                                <div v-else>Client does not have an over payment</div>
                                <div>
                                    <v-icon left>fas fa-check</v-icon>Total Additional Assets : {{formatCurrency(assets_total)}}
                                </div>
                                <div v-if="employee_contribution_overage > 0">
                                    <v-icon left>fas fa-check</v-icon>This client is paying {{formatCurrency(employee_contribution_overage)}} more than their employer's contribution.
                                </div>
                                <div v-if="has_debt">
                                    <v-icon left>fas fa-check</v-icon>Client has debt.
                                </div>
                                <div v-if="DFLSME">
                                    An appointment has been with DFL SME {{DFLSME.Agent}}
                                </div>
                                <v-btn @click="show_script = true; show_response = false">Process Results</v-btn>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <v-col cols="12" md="4" lg="12" class="notes">
                    <div class="fif-notes">
                        <fml-question-set ref="notes_section" auto-save :hide-title="true" :slug="slug" question-set-index="3" v-model="response" v-on:input="logEvent" v-on:submitted="submittedEvent" :redirect-on-save="false" />
                    </div>
                </v-col>
            </v-row>
        </v-row>

        <v-dialog eager v-model="show_script" width="50%">
            <FIFSurveyForm @qrs-confirmed="onQRSConfirm" @sme-confirmed="onSMEConfirm" :scripts="scripts" v-if="show_script" />
        </v-dialog>
        <v-expansion-panels v-if="hasRole('SuperAdmin')">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Debug
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                    <pre>{{response}}</pre>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <q-create-lead-referral :agent="this.user.Agent" v-model="create_referral_dialog" :licensing="licensing" v-on:refresh="setReferralLead"></q-create-lead-referral>

        <div v-show="hasRole('SuperAdmin')">
            <fml-question-set
                ref="hidden_section"
                auto-save :hide-title="true"
                :slug="slug"
                question-set-index="4"
                v-model="response"
                v-on:input="logEvent"
                v-on:submitted="submittedEvent"
                :redirect-on-save="false"
                @saved="loading = false"
            />
        </div>
    </v-container>
</template>

<script>
import FIFSurveyForm from './FIFSurvey/FIFSurveyForm.vue'
import FmlQuestionSet from '@/FML/views/FmlQuestionSet.vue'
import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue';
import QLeadLookup from '@/components/utils/QLeadLookup.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QCreateLeadReferral from '@/components/utils/QCreateLeadReferral.vue'

export default {
    data() {
        return {
            response: null,
            events: [],
            step: 0,
            lead: null,
            loading: false,
            create_referral_dialog: false,
            licensing: null,
            show_response: false,
            the_form: null,
            loaded: false,
            slug: 'financial-information-form',
            show_script: false,
            DFLSME: null
        }
    },

    mounted: function() {
        if (this.$route.query.lead_code != null && this.$route.query.lead_code != '') {
            this.findLead(this.$route.query.lead_code)
        }
        this.loadlicensing()
        this.loadForm(this.slug);
    },

    computed: {
        'agent_code': function() {
            if (this.response != null && this.response.AgentCode) {
                return this.response.AgentCode
            } else {
                return this.user.AgentCode
            }
        },
        'appended_query': function() {
            return { 'form_tab': this.step }
        },
        expenses_total: function() {
            //grabs all repeatable fields to get the question's answers and tallies them.
            var question_ids = {
                7: 'field_4291' //question ID : answer field name
            };
            var g = this;
            var t = 0;
            Object.keys(question_ids).forEach(function(id) {
                if (g.response == null || typeof g.response.FormResponseAnswers == 'undefined' || g.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                g.response.FormResponseAnswers[id].AnswerContent.forEach(function(answer) {
                    t = t + Number(answer[question_ids[id]].AnswerContent)
                })
            })

            return t;
        },
        income_1_total: function() {
            //grabs all repeatable fields to get the question's answers and tallies them.
            var question_ids = {
                8: 'field_1559' //question ID : answer field name
            };
            var g = this;
            var t = 0;
            Object.keys(question_ids).forEach(function(id) {
                if (g.response == null || typeof g.response.FormResponseAnswers == 'undefined' || g.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                g.response.FormResponseAnswers[id].AnswerContent.forEach(function(answer) {
                    t = t + Number(answer[question_ids[id]].AnswerContent)
                })
            })

            return t;
        },
        income_2_total: function() {
            //grabs all repeatable fields to get the question's answers and tallies them.
            var question_ids = {
                10: 'field_1542' //question ID : answer field name
            };
            var g = this;
            var t = 0;
            Object.keys(question_ids).forEach(function(id) {
                if (g.response == null || typeof g.response.FormResponseAnswers == 'undefined' || g.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                g.response.FormResponseAnswers[id].AnswerContent.forEach(function(answer) {
                    t = t + Number(answer[question_ids[id]].AnswerContent)
                })
            })

            return t;
        },
        income_total: function() {
            return this.income_1_total + this.income_2_total;
        },
        assets_total: function() {
            //grabs all repeatable fields to get the question's answers and tallies them.
            var question_ids = {
                6: 'field_4665' //question ID : answer field name
            };
            var g = this;
            var t = 0;
            Object.keys(question_ids).forEach(function(id) {
                if (g.response == null || typeof g.response.FormResponseAnswers == 'undefined' || g.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                g.response.FormResponseAnswers[id].AnswerContent.forEach(function(answer) {
                    t = t + Number(answer[question_ids[id]].AnswerContent)
                })
            })

            return t;
        },
        employee_contribution_overage: function() {
            //grabs all repeatable fields to get the question's answers and tallies them.
            var question_id = 6;
            var employee_field = 'field_4667'
            var employer_field = 'field_4668'
            var g = this;
            var t = 0;
            if (g.response == null || typeof g.response.FormResponseAnswers == 'undefined' || g.response.FormResponseAnswers[question_id].AnswerContent == null) {
                return 0;
            }
            g.response.FormResponseAnswers[question_id].AnswerContent.forEach(function(answer) {
                //tallies up the over contribution of the employee beyond what the employer matches.
                t = t + (Number(answer[employee_field].AnswerContent) - Number(answer[employer_field].AnswerContent));
            })
            return t;
        },
        has_debt: function() {
            //grabs all repeatable fields to get the question's answers and tallies them.
            var question_ids = {
                11: 'field_4715' //question ID : answer field name
            };
            var g = this;
            var t = false;
            Object.keys(question_ids).forEach(function(id) {
                if (g.response == null || typeof g.response.FormResponseAnswers == 'undefined' || g.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                g.response.FormResponseAnswers[id].AnswerContent.forEach(function(answer) {
                    if (Number(answer[question_ids[id]].AnswerContent) > 0) {
                        t = true
                    }
                })
            })
            return t;
        },
        has_over_payment: function() {
            //mortgage overpayment
            var num_question_ids = {
                4: 'field_6269' //question ID : answer field name
            };
            var overpayment_trigger_amount = 250;
            //debt over payment
            var bool_question_ids = {
                11: 'field_4716' //question ID : answer field name
            };
            var r = false;
            var g = this
            Object.keys(num_question_ids).forEach(function(id) {
                if (g.response == null || typeof g.response.FormResponseAnswers == 'undefined' || g.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                g.response.FormResponseAnswers[id].AnswerContent.forEach(function(answer) {
                    if (Number(answer[num_question_ids[id]].AnswerContent) > overpayment_trigger_amount) {
                        r = true;
                    }
                })
            })
            Object.keys(bool_question_ids).forEach(function(id) {
                if (g.response == null || typeof g.response.FormResponseAnswers == 'undefined' || g.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                g.response.FormResponseAnswers[id].AnswerContent.forEach(function(answer) {
                    if (answer[bool_question_ids[id]].AnswerContent === true || answer[bool_question_ids[id]].AnswerContent == "true") {
                        r = true;
                    }
                })
            })
            return r;
        },
        scripts: function() {
            // See: https://quility.atlassian.net/jira/software/projects/DEVST/boards/12?selectedIssue=DEVST-1695
            const scripts = []

            if (this.has_over_payment) {
                scripts.push('DFL')
            } else {
                if (this.employee_contribution_overage > 500) {
                    if (this.has_debt) {
                        scripts.push('DFL')
                    } else {
                        scripts.push('QRS')
                    }
                }

                if (this.assets_total > 20000) {
                    scripts.push('QRS')
                }
            }

            if (scripts.length == 0) {
                // Show DFL by default
                return ['DFL']
            }

            // Return scripts without duplicates
            return [...new Set(scripts)]
        },
    },

    methods: {
        nextStep: function(s) {
            if (this.step == 4) {
                return
            }
            if (typeof s == 'object') {
                this.step = this.step + 1
            } else {
                this.step = s
            }
        },
        logEvent: function(e) {
            this.events.push(e);
        },
        submittedEvent: function() {
            this.loading = false
            this.show_response = true
        },
        loadForm: function(slug) {
            var g = this
            g.loaded = false
            g.$set(g, "the_form", null);
            var response_id = this.$route.query.form_response_id ? this.$route.query.form_response_id : 'new';
            QuilityAPI.getFormWithSlug(slug, response_id)
                .then(function(data) {
                    g.$set(g, "the_form", data);
                    g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        getLeadFormResponse: function(leadcode, lead_assignment_id, form_question_id) {
            var g = this
            this.loading = true;
            QuilityAPI.getLeadFormResponse(form_question_id, leadcode, this.agent_code).then(function(json) {
                g.loading = false
                g.$set(g, "response", json.data)
                g.$set(g.response, "LeadAssignmentID", lead_assignment_id);
                g.step = 1
            }).catch(function(error) {
                g.isLoading = false
            })
        },
        findLead: function(lead) {
            if (this.returnObject) {
                var leadcode = lead.LeadCode
            } else {
                var leadcode = lead
            }

            this.step = 0;
            this.loading = true
            // Lazily load input items
            var g = this
            QuilityAPI.getLead(leadcode, this.agent_code).then(function(json) {
                g.$set(g, 'lead', json)
                g.$set(g.response, "LeadAssignmentID", json.ID);
                g.loading = false
            }).catch(function() {
                g.isLoading = false
            });
        },
        loadlicensing: function() {
            var g = this
            if (this.user.Agent.AgentCode == 'SFG0000001') {
                g.$set(g, 'licensing', [])
                return;
            }
            QuilityAPI.getAgentLicensing(this.user.Agent, this.role).then(function(resp) {
                if (resp.success) {
                    g.$set(g, 'licensing', resp.data.licenses)
                } else {
                    g.showError(resp.reason);
                }
            });
        },
        setReferralLead: function(lead) {
            this.lead = lead
        },
        sendEmail: function() {
            this.loading = true
            if (this.$refs.assets_section.loadedQuestionSet) {
                this.response.SubmittedInd = true;
                this.$nextTick(() => this.$refs.assets_section.saveResponse());
            }
        },
        onSMEConfirm(e) {
            if(('Agent' in e) && ('AgentCode' in e)){
                // DFL Fields
                this.response.FormResponseAnswers[64].AnswerContent = e.Agent
                this.response.FormResponseAnswers[63].AnswerContent = e.AgentCode

                // this.response.FormResponseAnswers[277].AnswerContent = e.Agent
                // this.response.FormResponseAnswers[278].AnswerContent = e.AgentCode

                this.DFLSME = e

                this.response.SubmittedInd = true;
                this.$nextTick(() => this.$refs.hidden_section.saveResponse());
                this.show_script = false

                // loading get's set to false when this.$refs.hidden_section emits its 'saved' event
                this.loading = true
            }
        },
        onQRSConfirm(e) {
            // QRS Fields
            this.response.FormResponseAnswers[64].AnswerContent = 'QRS Team'
            this.response.FormResponseAnswers[63].AnswerContent = 'QRS Team'

            // this.response.FormResponseAnswers[277].AnswerContent = 'QRS Team'
            // this.response.FormResponseAnswers[278].AnswerContent = 'QRS Team'

            this.$nextTick(() => this.$refs.hidden_section.saveResponse());
            this.show_script = false

                // loading get's set to false when this.$refs.hidden_section emits its 'saved' event
            this.loading = true
        }
    },

    watch: {
        'response.Lead.LeadCode': function(newV) {
            var g = this
            this.$nextTick(function() {
                g.lead = g.response.Lead
            })
        },
        'lead': function(newV, oldV) {
            if (this.$refs.assets_section.loadedQuestionSet) {
                this.getLeadFormResponse(newV.LeadCode, newV.LeadAssignmentID, this.$refs.assets_section.loadedQuestionSet.FormQuestions[0].ID)
            }
        },
        '$route.query.form_tab': function(newV, oldV) {
            this.step = newV
        },
        '$route.query.lead_code': function(newV, oldV) {
            if (typeof newV == 'undefined' || newV == null) {
                return
            }
            this.findLead(newV)
        },
        '$route.query.form_response_id': function(newV, oldV) {
            //we want to load a specific response id...
            if (typeof newV != 'undefined' && newV != null) {
                //this.$refs.assets_section.loadFormResponse(newV);
                return
            }
            //this.$refs.assets_section.loadFormResponseTemplate('new');
        },
        'step': function(newV) {
            var g = this
            if (newV == 4) {
                g.show_script = true
            }
            setTimeout(function() {
                g.$vuetify.goTo('#panel' + newV, {
                    duration: 500,
                    easing: 'easeOutCubic',
                    offset: 150
                })
            }, 250)
        }
    },

    components: {
        FmlQuestionSet,
        QMyClientsSubmenu,
        QLeadLookup,
        QCreateLeadReferral,
        FIFSurveyForm,
    },
}
</script>

<style lang="scss">
    .q-fml-financial-inforamtion-form {
        .fif-notes {
            background: #fff9c4;
            padding: 16px;
            box-sizing: border-box;
            margin-bottom: -36px;
        }

        .q-fml-question_field-wrapper {
            margin-top: 2rem;

            &:first-child {
                margin-top: 0;
            }

            .v-input:not(.v-textarea) {
                width: 250px;
            }

            // Make outer text field inner
            .v-input__prepend-outer {
                position: absolute;

                $icon-padding: 24px;

                // Add padding to input field that is placed immediately after the left icon
                & + .v-input__control .v-input__slot {
                    padding-left: $icon-padding;
                    box-sizing: border-box;
                }
            }
        }
    }
</style>
